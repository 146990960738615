@import '/styles/vars';

.cookiesPopup {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    background: $color_modal_bg;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.cookiesLink {
    color: $color_dark;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

.cookiesWrapper {
    padding: $gap12;
    max-width: 600px;
}

.cookiesContainer {
    padding: $gap30 $gap36 $gap36 $gap36;
    border-radius: $radius3;
    background: #fff;
    width: 100%;
    @include mobile {
        padding: $gap18 $gap24 $gap24 $gap24;
    }
}

.checkbox {
    margin: 0 0 20pxd 0;
}

.cookiesText {
    font-size: $font14;
    margin: 0 0 $gap24 0;
}

.cookiesTitle {
    font-size: $font24;
    font-weight: $medium;
    color: $color_dark;
    margin: 0 0 $gap12 0;
}
