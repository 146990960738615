@import '/styles/vars';

.group {
    display: flex;
    justify-content: flex-end;
    margin: -18px;
    > * {
        margin: 18px;
    }
    @include mobile {
        margin: -6px;
        > * {
            flex-shrink: 1;
            margin: 6px;
        }
    }
}

.center {
    justify-content: center;
}

.left {
    justify-content: flex-start;
}

.withBlanks {
    > * + * {
        margin-left: $gap36;
    }
}

.withBorder {
    //border-top: $dashed;
    padding: $gap48 0 0 0 !important;
    //margin: $gap48 0 0 0;
}

.fill {
    > * {
        width: 100%;
        flex-shrink: 1 !important;
    }
}

@include mobile {
    .group {
        flex-wrap: wrap;
        justify-content: space-between;
        > * {
            width: auto;
            flex-grow: 1;
            flex-shrink: 0;
        }
    }
    .withBorder {
        border: 0;
        padding: 0;
    }
    .withBlanks {
        justify-content: center;
        margin-left: -12px;
        margin-right: -12px;
        > * {
            margin: 0 12px;
        }
    }
}
