@import '/styles/vars';

.checkbox {
    margin: 0 0 $gap36 0;
    display: block;
    position: relative;
    text-align: left;
    align-self: center;
    + .checkbox {
        margin-top: -$gap12;
    }
    .description {
        color: $color_faded;
        display: block;
        margin-top: 3px;
    }
    label {
        user-select: none;
        cursor: pointer;
        display: inline-block;
        padding: 1px 0 0 36px;
        position: relative;
        > span {
            a {
                text-decoration: underline;
                //margin-left: 4px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        input {
            appearance: none;
            position: absolute;
            margin: 0;
            left: 0;
            top: 0;
            width: $gap24;
            height: $gap24;
            // border: $border;
            background: $color_light;
            // background: transparent;
            border-radius: $radius1;
            + i {
                transition: all $ease;
                position: absolute;
                left: 0;
                width: $gap24;
                height: $gap24;
                text-align: center;
                top: 0;
                opacity: 0;
                transform: scale(0.5);
                color: $color_dark;
                padding: 2px;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            &:checked {
                + i {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
    &.readonly {
        pointer-events: none;
        input {
            background: $color_border;
        }
    }
    @include mobile {
        margin: 0 0 $gap24 0;
        + .checkbox {
            margin-top: 0;
        }
    }
}
